import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import { LoggedInPageLayout } from "../components/page-layout";

import {
  DataGrid,
  GridColDef,
  GridRowSelectionModel,
  GridValueGetterParams,
  GridActionsCellItem,
  GridRowId,
  GridRowParams,
  GridTreeNodeWithRender,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import { Replay } from "@mui/icons-material";
import { Button, Typography, Container } from "@mui/material";

import { useSnackbar } from "notistack";

import {
  GetDatasetJobsCommand,
  GetDatasetJobsResponse,
  FetchDatasetCommand,
  FetchDatasetResponse,
  DatasetJobStatus,
} from "@airdodge-private/api-typescript-complete/lib/io/airdodge/internal/v1/GeoZonesAdmin_API_Commands_pb";

import { GeoZonesAdminService } from "@airdodge-private/api-typescript-complete/lib/io/airdodge/internal/v1/GeoZonesAdmin_API_connect";
import { zoneAdminClient } from "../api-client";
import { useClient } from "../api-client";
import { ConnectError, Code } from "@connectrpc/connect";

import { applicationRoutes } from "src/config";
import { useAuth0 } from "@auth0/auth0-react";
import usePermission from "src/authorization/usePermission";
import { AUTHORIZATION_ADMIN_WRITE } from "src/authorization/auth-types";

import { grpcErrorTranslator } from "src/helper/grpc-error-translator";

import RequirePermission from "src/authorization/require-permission";

interface DatasetJobRow {
  id: number;
  dataset_definition_id: number;
  status: DatasetJobStatus;
  created: Date;
  updated: Date;
}

var selectedDatasetJobIds: bigint[];

export const DatasetJobsPage: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, allowedAdminWrite] = usePermission(AUTHORIZATION_ADMIN_WRITE);


  const [rows, setRows] = useState<readonly any[]>([]);
  const { getAccessTokenSilently } = useAuth0();
  const client = useClient(GeoZonesAdminService);

  useEffect(() => {
    let isMounted = true;

    const loadDatasetJobs = async () => {
      const accessToken = await getAccessTokenSilently();

      const req = new GetDatasetJobsCommand({});
      req.jobStatus = [
        DatasetJobStatus.QUEUED,
        DatasetJobStatus.PENDING,
        DatasetJobStatus.DATA_DOWNLOADING,
        DatasetJobStatus.DATA_DOWNLOADED,
        DatasetJobStatus.DATA_IMPORTING,
        DatasetJobStatus.IMPORTING_COMPLETED,
        DatasetJobStatus.COMPLETED,
        DatasetJobStatus.FAILED,
        DatasetJobStatus.CANCELLED,
      ];

      const headers = new Headers();
      headers.set("Authorization", "Bearer " + accessToken);

      try {
        const rsp: GetDatasetJobsResponse = await client.getDatasetJobs(req, {
          headers: headers,
          timeoutMs: 10000,
        });

        console.log(rsp);

        const parsedRows = rsp.datasetJobs.map(
          (job) =>
            ({
              id: Number(job.id),
              dataset_definition_id: Number(job.datasetDefinitionId),
              status: job.jobStatus,
              created: new Date(Number(job.created?.seconds) * 1000),
              updated: job.updated
                ? new Date(Number(job.updated?.seconds) * 1000)
                : null,
            } as DatasetJobRow)
        );

        setRows(parsedRows);
      } catch (err) {
        enqueueSnackbar(grpcErrorTranslator("Error fetching jobs", err), {
          variant: "error",
          autoHideDuration: 5000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      }

      if (!isMounted) {
        return;
      }
    };

    loadDatasetJobs();

    return () => {
      isMounted = false;
    };
  }, [client, getAccessTokenSilently]);
  if (!user) {
    return null;
  }

  const onRowsSelectionHandler = (ids: GridRowSelectionModel) => {
    selectedDatasetJobIds = ids
      .map((id) => rows.find((row) => row.id === id))
      .map((row) => row.id);
    console.log(selectedDatasetJobIds);
  };

  async function fetchDataset(ids: bigint[]) {
    console.log("Fetch datasets " + ids);
    const accessToken = await getAccessTokenSilently();

    const request = new FetchDatasetCommand({});
    request.datasetDefinitionId = ids;
    const headers = new Headers();
    headers.set("Authorization", "Bearer " + accessToken);

    try {
      const resp: FetchDatasetResponse = await zoneAdminClient.fetchDataset(
        request,
        {
          headers: headers,
          timeoutMs: 10000,
        }
      );

      resp.datasetJob.forEach((job) => {
        enqueueSnackbar(
          "Job " +
            job.id +
            " for dataset " +
            job.datasetDefinitionId +
            " queued",
          {
            variant: "success",
            autoHideDuration: 5000,
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }
        );

        // TODO clear selection
      });

      console.log(resp);
    } catch (err) {
      enqueueSnackbar("Retrying jobs failed: " + err + ")", {
        variant: "error",
        autoHideDuration: 5000,
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });

      if (err instanceof ConnectError && err.code === Code.DeadlineExceeded) {
        console.log("Timeout");
      }
    }
  }
  async function fetchSelectedDatasets() {
    const datasetDefinitionIds = rows
      .filter((row) => row.id in selectedDatasetJobIds)
      .map((row) => row.dataset_definition_id);
    fetchDataset(datasetDefinitionIds);
  }

  const fetchDataSets = (id: GridRowId) => () => {
    const selectedRow: bigint[] = rows
      .filter((row: DatasetJobRow) => row.id === id)
      .map((row) => row.dataset_definition_id);

    fetchDataset(selectedRow);
  };

  const columns: GridColDef[] = [
    {
      field: "active",
      headerName: "Actions",
      type: "actions",
      width: 140,

      cellClassName: "actions",

      getActions: (params: GridRowParams) => 
     // TODO should be considering: !loading && allowedAdminWrite ?
      [
        <GridActionsCellItem
          icon={<Replay />}
          label="Re-fetch dataset"
          onClick={fetchDataSets(params.id)}
        />,
      ] 
      //: [],
    },

    {
      field: "id",
      headerName: "Job ID",
      width: 60,
    },
    {
      field: "dataset_definition_id",
      headerName: "Dataset definition ID",
      flex: 1,
      minWidth: 100,
      editable: false,
      renderCell: (params: GridRenderCellParams<DatasetJobRow, any>) => (
        <Link
          to={`/dataset/definition/edit/${params.row.dataset_definition_id}`}
        >
          {params.row.dataset_definition_id}
        </Link>
      ),
    },

    {
      field: "status",
      headerName: "Job status",
      flex: 1,
      minWidth: 120,
      type: "string",
      editable: false,
      valueGetter: (
        params: GridValueGetterParams<
          DatasetJobRow,
          any,
          GridTreeNodeWithRender
        >
      ) => {
        switch (params.row.status) {
          case DatasetJobStatus.UNSPECIFIED:
            return "Unspecified";
          case DatasetJobStatus.QUEUED:
            return "Queued";
          case DatasetJobStatus.PENDING:
            return "Pending";
          case DatasetJobStatus.DATA_DOWNLOADING:
            return "Data downloading";
          case DatasetJobStatus.DATA_DOWNLOADED:
            return "Data downloaded";
          case DatasetJobStatus.DATA_IMPORTING:
            return "Data importing";
          case DatasetJobStatus.IMPORTING_COMPLETED:
            return "Importing completed";
          case DatasetJobStatus.COMPLETED:
            return "Completed";
          case DatasetJobStatus.FAILED:
            return "Failed";
          case DatasetJobStatus.CANCELLED:
            return "Cancelled";
          default:
            return "Unknown";
        }
      },
    },
    {
      field: "created",
      headerName: "Job created",

      minWidth: 160,
      flex: 0.5,
      type: "dateTime",
      editable: false,
      valueGetter: (
        params: GridValueGetterParams<
          DatasetJobRow,
          any,
          GridTreeNodeWithRender
        >
      ) => {
        if (!params.row.created) {
          return null;
        }

        return params.row.created;
      },
    },
    {
      field: "updated",
      headerName: "Job updated",
      minWidth: 160,
      flex: 0.5,
      type: "dateTime",
      editable: false,
      valueGetter: (
        params: GridValueGetterParams<
          DatasetJobRow,
          any,
          GridTreeNodeWithRender
        >
      ) => {
        if (!params.row.updated) {
          return null;
        }

        return params.row.updated;
      },
    },
  ];

  return (
    <LoggedInPageLayout>
      <Container maxWidth={false}>
        <Typography variant="h4" component="h1" gutterBottom>
          Dataset jobs
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          A dataset job fetches data from the source, stores the raw data in a
          GCS bucket and then imports the data into the database.
        </Typography>

        <DataGrid
          getRowId={(row: DatasetJobRow) => row.id}
          rows={rows}
          onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          columns={columns}
          getRowHeight={() => "auto"}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          checkboxSelection
          disableRowSelectionOnClick
        />


<RequirePermission to={AUTHORIZATION_ADMIN_WRITE} >

        <Button variant="contained" onClick={fetchSelectedDatasets}>
          Rerun selected jobs
        </Button>
        </RequirePermission>
      </Container>
    </LoggedInPageLayout>
  );
};
