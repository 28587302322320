import React, { useEffect, useState } from "react";

import {
  TextField,
  Autocomplete,
  Typography,
  Button,
  Container,
  Box,
  Stack,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";

import { useSnackbar } from "notistack";

import {
  DatasetDefinition,
  RemoteDataSourceType,
  AddDatasetDefinitionCommand,
  AddDatasetDefinitionResponse,
  EditDatasetDefinitionCommand,
  EditDatasetDefinitionResponse,
  RemoteDataSource,
  URLGeneratorSettings,
  GetDatasetDefinitionsCommand,
  GetDatasetDefinitionsResponse,
} from "@airdodge-private/api-typescript-complete/lib/io/airdodge/internal/v1/GeoZonesAdmin_API_Commands_pb";
import { Duration } from "@airdodge-private/api-typescript-complete/lib/google/protobuf/duration_pb";
import {
  GetFeatureTypesCommand,
  GetFeatureTypesResponse,
} from "@airdodge-private/api-typescript-complete/lib/io/airdodge/dtsp/geozones/v1/GeoZones_API_Commands_pb";
import { GeoZonesService } from "@airdodge-private/api-typescript-complete/lib/io/airdodge/dtsp/geozones/v1/GeoZones_API_connect";
import { useClient } from "../api-client";
import { ConnectError, Code } from "@connectrpc/connect";
import {
  AuthorativeLevel,
  FeatureType,
} from "@airdodge-private/api-typescript-complete/lib/io/airdodge/dtsp/geozones/v1/GeoZones_Common_pb";
import { zoneAdminClient } from "../api-client";

import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";

import { parse, toSeconds } from "iso8601-duration";
import { applicationRoutes } from "src/config";
import { countries, CountryType } from "../country-codes";
import { grpcErrorTranslator } from "src/helper/grpc-error-translator";
import RequirePermission from "src/authorization/require-permission";
import { AUTHORIZATION_ADMIN_WRITE } from "src/authorization/auth-types";

const URL_GENERATOR_KEY_AREA = "area";
const URL_GENERATOR_KEY_FREEMARKER_TEMPLATE = "freemarkerTemplate";
const URL_GENERATOR_KEY_SECRET_SERVICE = "secretService";
const URL_GENERATOR_KEY_FEATURE_SERVICE = "featureService";
const URL_GENERATOR_KEY_LFV_DAIM_LAYER = "layer";
const URL_GENERATOR_KEY_OPENAIP_COUNTRY = "country";
const URL_GENERATOR_KEY_STATIC_URL = "url";

export type DatasetDefinitionFormProps = {
  id?: bigint;
  mode: DatasetDefinitionFormMode;
};

export enum DatasetDefinitionFormMode {
  CREATE,
  EDIT,
  COPY,
}

export const DatasetDefinitionForm: React.FC<DatasetDefinitionFormProps> = (
  props
) => {
  const lfvDaimLayers: readonly string[] = [
    "mais:RSTA",
    "mais:DNGA",
    "mais:ATZ",
    "mais:TIZ",
    "mais:CTR",
    "DAIM_TOPO:HKP1K",
    "DAIM_TOPO:RWY5K",
    "DAIM_TOPO:SUP",
  ];

  interface DataSourceType {
    code: number;
    label: string;
  }

  const allRemoteDataSourceTypes: readonly DataSourceType[] = [
    {
      code: RemoteDataSourceType.ARCGIS_FEATURE_SERVICE,
      label: "ArcGIS Cloud",
    },
    {
      code: RemoteDataSourceType.OVERPASS_API,
      label: "Open Street Map",
    },
    {
      code: RemoteDataSourceType.LFV_DAIM,
      label: "LFV Daim",
    },
    {
      code: RemoteDataSourceType.OPENAIP,
      label: "OpenAIP",
    },
    {
      code: RemoteDataSourceType.STATIC,
      label: "Static",
    },
  ];

  interface AuthorativeLevelType {
    code: AuthorativeLevel;
    label: string;
  }

  const authorativeLevels: readonly AuthorativeLevelType[] = [
    {
      code: AuthorativeLevel.AUTHORATIVE,
      label: "Authorative (official source)",
    },
    {
      code: AuthorativeLevel.NON_AUTHORATIVE,
      label: "Non-Authorative (unofficial source)",
    },
  ];

  interface DurationType {
    code: string;
    label: string;
  }

  const validDurations: readonly DurationType[] = [
    {
      code: "P1D",
      label: "Every day",
    },
    {
      code: "P7D",
      label: "Every week",
    },
    {
      code: "P1M",
      label: "Every month",
    },
  ];
  interface ConverterNames {
    code: string;
    label: string;
  }

  const converterBeans: readonly ConverterNames[] = [
    {
      code: "overpassDatasetConverter",
      label: "OpenStreetMap data",
    },
    {
      code: "avinorCtrTizConverter",
      label: "Avinor ArcGIS TIZ+CTR",
    },
    {
      code: "avinorRPASProcessor",
      label: "Avinor ArcGIS RPAS 5km converter",
    },
    {
      code: "lfvDaimProcessor",
      label: "LFV Daim processor",
    },
    {
      code: "openAIPProcessor",
      label: "OpenAIP processor",
    },
    {
      code: "lfvObstaclesDatasetConverter",
      label: "LFV obstacles",
    },
    {
      code: "kartverketHinderDatasetConverter",
      label: "NO Kartverket obstacles",
    },
  ];

  const contentTypes: readonly string[] = [
    "application/json",
    "application/xml",
    "text/csv",
    "application/zip",
  ];

  const navigate = useNavigate();
  const { user } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();

  const { getAccessTokenSilently } = useAuth0();
  const client = useClient(GeoZonesService);

  const [active, setActive] = useState<boolean>(true);
  const [id, setID] = useState<bigint>();
  const [version, setVersion] = useState<number>();
  const [name, setName] = useState("");
  const [geographicalCoverage, setGeopgrahicalCoverage] =
    useState<CountryType | null>(
      countries.filter((country) => country.code === "NO")[0]
    );
  const [remoteDataSource, setRemoteDataSource] =
    useState<DataSourceType | null>(allRemoteDataSourceTypes[0]);
  const [urlGeneratorParametersOSMArea, setUrlGeneratorParametersOSMArea] =
    useState<string | undefined>("");
  const [
    urlGeneratorParametersOSMTemplate,
    setUrlGeneratorParametersOSMTemplate,
  ] = useState<string | undefined>("");
  const [
    urlGeneratorParametersArcGISSecretService,
    setUrlGeneratorParametersArcGISSecretService,
  ] = useState<string | undefined>("");
  const [
    urlGeneratorParametersLFVDaimLayer,
    setUrlGeneratorParametersLFVDaimLayer,
  ] = useState<string | undefined>("");
  const [
    urlGeneratorParametersArcGISFeatureServer,
    setUrlGeneratorParametersArcGISFeatureServer,
  ] = useState<string | undefined>("");
  const [
    urlGeneratorParametersOpenAIPCountry,
    setUrlGeneratorParametersOpenAIPCountry,
  ] = useState<string | undefined>("");
  const [urlGeneratorParametersStaticUrl, setUrlGeneratorParametersStaticUrl] =
    useState<string | undefined>("");

  const [updateInterval, setUpdateInterval] = useState<DurationType | null>(
    validDurations[0]
  );
  const [converterBeanName, setConverterBeanName] =
    useState<ConverterNames | null>(converterBeans[0]);
  const [contentType, setContentType] = useState<string | null>(
    "application/json"
  );
  const [authorativeLevel, setAuthorativeLevel] =
    useState<AuthorativeLevelType | null>(authorativeLevels[1]);
  const [featuresIncluded, setFeaturesIncluded] = useState<FeatureType[]>([]);
  const [allFeatureTypes, setAllFeatureTypes] = useState<FeatureType[]>([]);

  useEffect(() => {
    let isMounted = true;

    const loadFeatureTypes = async () => {
      const accessToken = await getAccessTokenSilently();

      const featureTypesRequest = new GetFeatureTypesCommand({});
      const headers = new Headers();
      headers.set("Authorization", "Bearer " + accessToken);

      try {
        const featureTypes: GetFeatureTypesResponse =
          await client.getFeatureTypes(featureTypesRequest, {
            headers: headers,
            timeoutMs: 10000,
          });

        console.log(featureTypes);

        setAllFeatureTypes(featureTypes.featureTypes);
      } catch (err) {
        enqueueSnackbar(
          grpcErrorTranslator("Error loading featuretypes", err),
          {
            variant: "error",
            autoHideDuration: 5000,
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }
        );
      }

      if (!isMounted) {
        return;
      }
    };

    loadFeatureTypes();

    if (props.mode === DatasetDefinitionFormMode.EDIT) {
      loadDatasetDefinition(props.id!);
    } else if (props.mode === DatasetDefinitionFormMode.COPY) {
      loadDatasetDefinition(props.id!);
      setID(BigInt(-1));
    }

    return () => {
      isMounted = false;
    };
  }, [client, getAccessTokenSilently]);
  if (!user) {
    return null;
  }

  async function loadDatasetDefinition(id: bigint) {
    const request = new GetDatasetDefinitionsCommand();
    request.datasetDefinitionId = Array.of(id);
    const accessToken = await getAccessTokenSilently();

    const headers = new Headers();
    headers.set("Authorization", "Bearer " + accessToken);

    try {
      const resp: GetDatasetDefinitionsResponse =
        await zoneAdminClient.getDatasetDefinitions(request, {
          headers: headers,
          timeoutMs: 10000,
        });

      if (resp.datasetDefinitions.length === 1) {
        let dd = resp.datasetDefinitions[0];

        setActive(dd.active);
        setID(dd.id);
        setVersion(dd.version);
        setName(dd.name);
        setGeopgrahicalCoverage(
          countries.filter(
            (country) => country.code === dd.geographicalCoverage
          )[0]
        );
        setRemoteDataSource(
          allRemoteDataSourceTypes.filter(
            (dataSource) => dataSource.code === dd.dataSource?.type
          )[0]
        );
        /* 
        TODO use seconds as definition for timer interval
        setUpdateInterval(
          validDurations.filter(
            (duration) =>
              duration.code === dd.updateInterval?.seconds.toString()
          )[0]
        );*/
        setAuthorativeLevel(
          authorativeLevels.filter(
            (level) => level.code === dd.authorativeLevel
          )[0]
        );
        setFeaturesIncluded(dd.featuresIncluded);
        setContentType(dd.dataSource!.contentType);
        setConverterBeanName(
          converterBeans.filter(
            (converter) => converter.code === dd.dataSource?.converterBeanName
          )[0]
        );

        setUrlGeneratorParametersOSMArea(
          dd.dataSource?.urlGeneratorSettings?.parameters[
            URL_GENERATOR_KEY_AREA
          ]
        );
        setUrlGeneratorParametersOSMTemplate(
          dd.dataSource?.urlGeneratorSettings?.parameters[
            URL_GENERATOR_KEY_FREEMARKER_TEMPLATE
          ]
        );

        setUrlGeneratorParametersArcGISSecretService(
          dd.dataSource?.urlGeneratorSettings?.parameters[
            URL_GENERATOR_KEY_SECRET_SERVICE
          ]
        );
        setUrlGeneratorParametersArcGISFeatureServer(
          dd.dataSource?.urlGeneratorSettings?.parameters[
            URL_GENERATOR_KEY_FEATURE_SERVICE
          ]
        );
        setUrlGeneratorParametersLFVDaimLayer(
          dd.dataSource?.urlGeneratorSettings?.parameters[
            URL_GENERATOR_KEY_LFV_DAIM_LAYER
          ]
        );
        setUrlGeneratorParametersOpenAIPCountry(
          dd.dataSource?.urlGeneratorSettings?.parameters[
            URL_GENERATOR_KEY_OPENAIP_COUNTRY
          ]
        );
        setUrlGeneratorParametersStaticUrl(
          dd.dataSource?.urlGeneratorSettings?.parameters[
            URL_GENERATOR_KEY_STATIC_URL
          ]
        );
      } else {
        enqueueSnackbar("Dataset definition failed", {
          variant: "error",
          autoHideDuration: 5000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      }

      console.log(resp);
    } catch (err) {
      enqueueSnackbar(
        grpcErrorTranslator("Error loading dataset definition", err),
        {
          variant: "error",
          autoHideDuration: 5000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        }
      );
    }
  }

  function buildDatasetDefinitionFromForm(): DatasetDefinition {
    const dd = new DatasetDefinition();
    if (props.mode === DatasetDefinitionFormMode.EDIT) {
      dd.id = id!;
      dd.version = version!;
    }
    dd.active = active;
    dd.name = name;
    dd.geographicalCoverage = geographicalCoverage!.code;
    const source = new RemoteDataSource();
    source.type = remoteDataSource!.code;
    source.converterBeanName = converterBeanName!.code;
    source.contentType = contentType!;

    dd.dataSource = source;

    const ui = new Duration();
    // Convert to bigint
    ui.seconds = BigInt(toSeconds(parse(updateInterval!.code)));

    dd.updateInterval = ui;
    dd.authorativeLevel = authorativeLevel!.code;
    dd.featuresIncluded = featuresIncluded;

    const url = new URLGeneratorSettings();

    // TODO should be dynamic but dont know how to do it
    if (remoteDataSource?.code === RemoteDataSourceType.OVERPASS_API) {
      url.parameters[URL_GENERATOR_KEY_AREA] = urlGeneratorParametersOSMArea!;
      url.parameters[URL_GENERATOR_KEY_FREEMARKER_TEMPLATE] =
        urlGeneratorParametersOSMTemplate!;
    } else if (
      remoteDataSource?.code === RemoteDataSourceType.ARCGIS_FEATURE_SERVICE
    ) {
      url.parameters[URL_GENERATOR_KEY_SECRET_SERVICE] =
        urlGeneratorParametersArcGISSecretService!;
      url.parameters[URL_GENERATOR_KEY_FEATURE_SERVICE] =
        urlGeneratorParametersArcGISFeatureServer!;
    } else if (remoteDataSource?.code === RemoteDataSourceType.LFV_DAIM) {
      url.parameters[URL_GENERATOR_KEY_LFV_DAIM_LAYER] =
        urlGeneratorParametersLFVDaimLayer!;
    } else if (remoteDataSource?.code === RemoteDataSourceType.OPENAIP) {
      url.parameters[URL_GENERATOR_KEY_OPENAIP_COUNTRY] =
        urlGeneratorParametersOpenAIPCountry!;
    } else if (remoteDataSource?.code === RemoteDataSourceType.STATIC) {
      url.parameters[URL_GENERATOR_KEY_STATIC_URL] =
        urlGeneratorParametersStaticUrl!;
    }

    source.urlGeneratorSettings = url;

    return dd;
  }

  async function addNewDatasetDefinition() {
    const dd = buildDatasetDefinitionFromForm();

    const request = new AddDatasetDefinitionCommand();
    request.datasetDefinition = dd;

    const accessToken = await getAccessTokenSilently();

    const headers = new Headers();
    headers.set("Authorization", "Bearer " + accessToken);

    try {
      const resp: AddDatasetDefinitionResponse =
        await zoneAdminClient.addDatasetDefinition(request, {
          headers: headers,
          timeoutMs: 10000,
        });

      if (resp.datasetDefinition) {
        enqueueSnackbar(
          "New Dataset definition " + resp.datasetDefinition.id + " stored",
          {
            variant: "success",
            autoHideDuration: 5000,
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }
        );
        navigate(applicationRoutes.datasetDefinitions.to);
      } else {
        enqueueSnackbar("Adding new Dataset definition failed", {
          variant: "error",
          autoHideDuration: 5000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      }

      console.log(resp);
    } catch (err) {
      enqueueSnackbar(
        grpcErrorTranslator("Error adding dataset definition", err),
        {
          variant: "error",
          autoHideDuration: 5000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        }
      );
    }
  }

  async function editDatasetDefinition() {
    const dd = buildDatasetDefinitionFromForm();

    const request = new EditDatasetDefinitionCommand();
    request.datasetDefinition = dd;

    const accessToken = await getAccessTokenSilently();

    const headers = new Headers();
    headers.set("Authorization", "Bearer " + accessToken);

    try {
      const resp: EditDatasetDefinitionResponse =
        await zoneAdminClient.editDatasetDefinition(request, {
          headers: headers,
          timeoutMs: 10000,
        });

      if (resp.datasetDefinition) {
        enqueueSnackbar(
          "Dataset definition " + resp.datasetDefinition.id + " updated",
          {
            variant: "success",
            autoHideDuration: 5000,
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }
        );
        navigate(applicationRoutes.datasetDefinitions.to);
      } else {
        enqueueSnackbar("Updating Dataset definition failed", {
          variant: "error",
          autoHideDuration: 5000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      }

      console.log(resp);
    } catch (err) {
      enqueueSnackbar(
        grpcErrorTranslator("Error updating dataset definition", err),
        {
          variant: "error",
          autoHideDuration: 5000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        }
      );
    }
  }

  async function searchOSMNormatim(params: string) {
    fetch(
      "https://nominatim.openstreetmap.org/search?format=jsonv2&featureType=country&q=" +
        params
    )
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        if (json.length > 0) {
          let code: number = json[0].osm_id as number;
          code = code + 3600000000;
          setUrlGeneratorParametersOSMArea(String(code));
        } else {
          setUrlGeneratorParametersOSMArea("");
        }
      })
      .catch((error) => console.error(error));
  }

  return (
    <RequirePermission to={AUTHORIZATION_ADMIN_WRITE} fallback={<div><Typography variant="h6" component="h1">Unauthorized - you do not have the necessary permissions to see this function</Typography></div>}>

    
    <Container maxWidth={false} disableGutters>
      <Typography variant="h4" component="h1" gutterBottom>
        {props.mode === DatasetDefinitionFormMode.CREATE ? "Create " : null}
        {props.mode === DatasetDefinitionFormMode.EDIT
          ? "Edit existing "
          : null}
        {props.mode === DatasetDefinitionFormMode.COPY
          ? "Copy existing "
          : null}
        Dataset definition
      </Typography>
      <Typography variant="h6" component="h1" gutterBottom>
        A dataset definition defines a data source to fetch data from as well as
        metadata about the source.
      </Typography>

      <TextField
        label="Datasource friendly name"
        onChange={(e) => setName(e.target.value)}
        required
        variant="outlined"
        color="secondary"
        type="text"
        sx={{ mb: 3 }}
        fullWidth
        value={name}
      />

      <Autocomplete
        options={countries}
        autoHighlight
        value={geographicalCoverage}
        onChange={(_event: any, newValue: CountryType | null) => {
          setGeopgrahicalCoverage(newValue);
        }}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        sx={{ mb: 3 }}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <img
              loading="lazy"
              width="20"
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              alt=""
            />
            {option.label} ({option.code})
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Geographical coverage (country) for these data"
          />
        )}
      />

      <Autocomplete
        options={allRemoteDataSourceTypes}
        value={remoteDataSource}
        onChange={(_event: any, newValue: DataSourceType | null) => {
          setRemoteDataSource(newValue);
        }}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        autoHighlight
        sx={{ mb: 3 }}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField {...params} required label="Remote datasource type" />
        )}
      />

      {remoteDataSource?.code === RemoteDataSourceType.OVERPASS_API ? (
        <>
          <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
            <TextField
              label="OSM Area code"
              //  onChange={(e) => setUrlGeneratorParametersOSMArea(e.target.value)}
              value={urlGeneratorParametersOSMArea}
              required
              disabled={true}
              variant="outlined"
              color="secondary"
              type="text"
              sx={{ mb: 1 }}
              fullWidth
            />
            <TextField
              label="Search for OSM country name"
              onChange={(e) => searchOSMNormatim(e.target.value)}
              required
              variant="outlined"
              color="secondary"
              type="text"
              sx={{ mb: 1 }}
              fullWidth
            />
          </Stack>

          <TextField
            label="OSM Request template (xxxxxx.ftlh)"
            onChange={(e) =>
              setUrlGeneratorParametersOSMTemplate(e.target.value)
            }
            value={urlGeneratorParametersOSMTemplate}
            required
            variant="outlined"
            color="secondary"
            type="text"
            sx={{ mb: 3 }}
            fullWidth
          />
        </>
      ) : null}
      {remoteDataSource?.code ===
      RemoteDataSourceType.ARCGIS_FEATURE_SERVICE ? (
        <>
          <TextField
            label="Service identifier (XXXX part of URL  https://services.argis.com/XXXX/ArcGIS/...)"
            onChange={(e) =>
              setUrlGeneratorParametersArcGISSecretService(e.target.value)
            }
            value={urlGeneratorParametersArcGISSecretService}
            required
            variant="outlined"
            color="secondary"
            type="text"
            sx={{ mb: 1 }}
            fullWidth
          />

          <TextField
            label="Feature service (YYYY part of URL https://services.arcgis.com/XXXX/ArcGIS/rest/services/YYYY/FeatureServer)"
            onChange={(e) =>
              setUrlGeneratorParametersArcGISFeatureServer(e.target.value)
            }
            value={urlGeneratorParametersArcGISFeatureServer}
            required
            variant="outlined"
            color="secondary"
            type="text"
            sx={{ mb: 3 }}
            fullWidth
          />
        </>
      ) : null}
      {remoteDataSource?.code === RemoteDataSourceType.LFV_DAIM ? (
        <>
          <Autocomplete
            options={lfvDaimLayers}
            value={urlGeneratorParametersLFVDaimLayer}
            onChange={(_event: any, newValue: string | null) => {
              if (newValue === null) {
                setUrlGeneratorParametersLFVDaimLayer(undefined);
              } else {
                setUrlGeneratorParametersLFVDaimLayer(newValue);
              }
            }}
            autoHighlight
            sx={{ mb: 3 }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="LFV Daim data layer (see https://daim.lfv.se/echarts/dronechart/API/)"
              />
            )}
          />
        </>
      ) : null}
      {remoteDataSource?.code === RemoteDataSourceType.OPENAIP ? (
        <>
          <TextField
            label="Country code 2 letter lowercase"
            onChange={(e) =>
              setUrlGeneratorParametersOpenAIPCountry(e.target.value)
            }
            value={urlGeneratorParametersOpenAIPCountry}
            required
            variant="outlined"
            color="secondary"
            type="text"
            sx={{ mb: 1 }}
            fullWidth
          />
        </>
      ) : null}
      {remoteDataSource?.code === RemoteDataSourceType.STATIC ? (
        <>
          <TextField
            label="Full url to static file"
            onChange={(e) => setUrlGeneratorParametersStaticUrl(e.target.value)}
            value={urlGeneratorParametersStaticUrl}
            required
            variant="outlined"
            color="secondary"
            type="text"
            sx={{ mb: 1 }}
            fullWidth
          />
        </>
      ) : null}
      <Autocomplete
        options={validDurations}
        value={updateInterval}
        onChange={(_event: any, newValue: DurationType | null) => {
          setUpdateInterval(newValue);
        }}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        autoHighlight
        sx={{ mb: 3 }}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField {...params} required label="Update interval" />
        )}
      />
      <FormGroup sx={{ mb: 3 }}>
        <FormControlLabel
          control={
            <Switch
              checked={active}
              onChange={(_event: any, newValue: boolean | null) => {
                setActive(newValue!);
              }}
            />
          }
          label="Fetch dataset at the defined update interval"
        />
      </FormGroup>

      <Autocomplete
        options={authorativeLevels}
        value={authorativeLevel}
        onChange={(_event: any, newValue: AuthorativeLevelType | null) => {
          setAuthorativeLevel(newValue);
        }}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        autoHighlight
        sx={{ mb: 3 }}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField {...params} required label="Authorative level" />
        )}
      />

      <Autocomplete
        options={converterBeans}
        value={converterBeanName}
        onChange={(_event: any, newValue: ConverterNames | null) => {
          setConverterBeanName(newValue);
        }}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        autoHighlight
        sx={{ mb: 3 }}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField {...params} required label="Data converter" />
        )}
      />

      <Autocomplete
        options={contentTypes}
        value={contentType}
        onChange={(_event: any, newValue: string | null) => {
          setContentType(newValue);
        }}
        autoHighlight
        sx={{ mb: 3 }}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <TextField {...params} required label="Content type of source data" />
        )}
      />
      <Autocomplete
        options={allFeatureTypes}
        groupBy={(option) => option.id.substring(0, option.id.indexOf(":"))}
        value={featuresIncluded}
        onChange={(_event: any, newValue: FeatureType[] | null) => {
          setFeaturesIncluded(newValue == null ? [] : newValue);
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        autoHighlight
        multiple
        sx={{ mb: 3 }}
        getOptionLabel={(option) => option.id + " (" + option.name + ")"}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Feature types provided by this data source. This is used to 1) filter inbound data, and 2) provide an overview per country of missing data"
          />
        )}
      />

      {props.mode === DatasetDefinitionFormMode.EDIT ? (
        <Button
          variant="contained"
          type="submit"
          onClick={editDatasetDefinition}
        >
          Update
        </Button>
      ) : null}

      {props.mode === DatasetDefinitionFormMode.COPY ||
      props.mode === DatasetDefinitionFormMode.CREATE ? (
        <Button
          variant="contained"
          type="submit"
          onClick={addNewDatasetDefinition}
        >
          Save new
        </Button>
      ) : null}
    </Container>
    </RequirePermission>
  );
};
