import { createContext, useContext, useMemo } from "react";
import { ServiceType } from "@bufbuild/protobuf";
import {
  createPromiseClient,
  PromiseClient,
  Transport,
} from "@connectrpc/connect";
import { createGrpcWebTransport } from "@connectrpc/connect-web";
import { Config } from "./config";
import { GeoZonesAdminService } from "@airdodge-private/api-typescript-complete/lib/io/airdodge/internal/v1/GeoZonesAdmin_API_connect";

// This transport is going to be used throughout the app
const defaultTransport = createGrpcWebTransport({
  baseUrl: Config.api_core_url,
});

// A context to override the default transport in tests
export const TransportContext = createContext<Transport>(defaultTransport);

/**
 * Get a promise client for the given service.
 */
export function useClient<T extends ServiceType>(service: T): PromiseClient<T> {
  const transport = useContext(TransportContext);
  // We memoize the client, so that we only create one instance per service.
  return useMemo(
    () => createPromiseClient(service, transport),
    [service, transport]
  );
}

export const zoneAdminClient = createPromiseClient(
  GeoZonesAdminService,
  defaultTransport
);
