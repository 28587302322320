import React, { useEffect, useState } from "react";

import {
  TextField,
  Autocomplete,
  Typography,
  Button,
  Container,
  Box,
} from "@mui/material";
import { MuiColorInput } from "mui-color-input";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridRenderCellParams,
  GridValueGetterParams,
  GridTreeNodeWithRender,
  GridRowProps,
} from "@mui/x-data-grid";

import { useSnackbar } from "notistack";

import {
  AddMapVisualisationCommand,
  AddMapVisualisationResponse,
  EditMapVisualisationCommand,
  EditMapVisualisationResponse,
  GetMapVisualisationCommand,
  GetMapVisualisationResponse,
  FeatureTypeVisualisationProperties,
  FeatureTypeVisualisation,
  MapVisualisation,
} from "@airdodge-private/api-typescript-complete/lib/io/airdodge/internal/v1/GeoZonesAdmin_API_Commands_pb";
import { FeatureType } from "@airdodge-private/api-typescript-complete/lib/io/airdodge/dtsp/geozones/v1/GeoZones_Common_pb";
import {
  GetFeatureTypesCommand,
  GetFeatureTypesResponse,
} from "@airdodge-private/api-typescript-complete/lib/io/airdodge/dtsp/geozones/v1/GeoZones_API_Commands_pb";
import { GeoZonesService } from "@airdodge-private/api-typescript-complete/lib/io/airdodge/dtsp/geozones/v1/GeoZones_API_connect";

import { useClient } from "../api-client";
import { zoneAdminClient } from "../api-client";

import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";

import { applicationRoutes } from "src/config";
import { grpcErrorTranslator } from "src/helper/grpc-error-translator";
import { GeoZonesAdminService } from "@airdodge-private/api-typescript-complete/lib/io/airdodge/internal/v1/GeoZonesAdmin_API_connect"
import RequirePermission from "src/authorization/require-permission";
import { AUTHORIZATION_ADMIN_WRITE } from "src/authorization/auth-types";

export type DatasetDefinitionFormProps = {
  id?: bigint;
  mode: MapVisualisationFormMode;
};

export enum MapVisualisationFormMode {
  CREATE,
  EDIT,
  COPY,
}
interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}
interface FeatureTypeVisualisationRow {
  id: number;
  featureType: FeatureType;
  properties: FeatureTypeVisualisationProperties;
  isNew: boolean;
}
export const MapVisualisationForm: React.FC<DatasetDefinitionFormProps> = (
  props
) => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();

  const { getAccessTokenSilently } = useAuth0();
  const adminClient = useClient(GeoZonesAdminService);
  const zoneClient = useClient(GeoZonesService);

  const [id, setID] = useState<bigint>();
  const [name, setName] = useState("");
  const [defaultFillColor, setDefaultFillColour] =
    useState<string>("#FF000088");
  const [rows, setRows] = useState<FeatureTypeVisualisationRow[]>([]);

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const [allFeatureTypes, setAllFeatureTypes] = useState<FeatureType[]>([]);

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: FeatureTypeVisualisationRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      const id = rows.length + 1;
      const properties = new FeatureTypeVisualisationProperties();
      properties.fillColor = "#FF000088";
      setRows((oldRows) => [
        ...oldRows,
        {
          id,
          properties: properties,
          isNew: true,
        } as FeatureTypeVisualisationRow,
      ]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add visualisation override
        </Button>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    let isMounted = true;

    const loadFeatureTypes = async () => {
      const accessToken = await getAccessTokenSilently();

      const featureTypesRequest = new GetFeatureTypesCommand({});
      const headers = new Headers();
      headers.set("Authorization", "Bearer " + accessToken);

      try {
        const featureTypes: GetFeatureTypesResponse =
          await zoneClient.getFeatureTypes(featureTypesRequest, {
            headers: headers,
            timeoutMs: 10000,
          });

        console.log(featureTypes);

        setAllFeatureTypes(
          featureTypes.featureTypes.sort((a, b) => {
            return a.id > b.id ? 1 : -1;
          })
        );
      } catch (err) {
        enqueueSnackbar(
          grpcErrorTranslator("Error loading featuretypes", err),
          {
            variant: "error",
            autoHideDuration: 5000,
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }
        );
      }

      if (!isMounted) {
        return;
      }
    };

    loadFeatureTypes();

    if (props.mode === MapVisualisationFormMode.EDIT) {
      loadMapVisualisation(props.id!);
    } else if (props.mode === MapVisualisationFormMode.COPY) {
      loadMapVisualisation(props.id!);
      setID(BigInt(-1));
    }

    return () => {
      isMounted = false;
    };
  }, [adminClient, getAccessTokenSilently]);
  if (!user) {
    return null;
  }

  async function loadMapVisualisation(id: bigint) {
    const request = new GetMapVisualisationCommand();
    request.mapVisualisationId = id;
    const accessToken = await getAccessTokenSilently();

    const headers = new Headers();
    headers.set("Authorization", "Bearer " + accessToken);

    try {
      const resp: GetMapVisualisationResponse =
        await zoneAdminClient.getMapVisualisation(request, {
          headers: headers,
          timeoutMs: 10000,
        });

      if (resp.mapVisualisation) {
        let mapvis = resp.mapVisualisation;

        setID(mapvis.id);
        setName(mapvis.name);
        setDefaultFillColour(mapvis.defaultProperties!.fillColor);

        var counter = 0;
        const parsedRows: FeatureTypeVisualisationRow[] =
          mapvis.featureTypeVisualisations.map(
            (featureTypeVisualisation) =>
              ({
                id: counter++,
                featureType: featureTypeVisualisation.featureType,
                properties: featureTypeVisualisation.properties!,
              } as FeatureTypeVisualisationRow)
          );

        setRows(parsedRows);
      } else {
        enqueueSnackbar("Map visualisation not found", {
          variant: "error",
          autoHideDuration: 5000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      }

      console.log(resp);
    } catch (err) {
      enqueueSnackbar(
        grpcErrorTranslator("Error loading map visualisation", err),
        {
          variant: "error",
          autoHideDuration: 5000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        }
      );
    }
  }

  async function addNew() {
    const request = new AddMapVisualisationCommand();
    request.name = name;
    const defaultProperties: FeatureTypeVisualisationProperties =
      new FeatureTypeVisualisationProperties();
    defaultProperties.fillColor = defaultFillColor!;
    request.defaultProperties = defaultProperties;

    rows.map((row) => {
      const featureTypeVisualisation = new FeatureTypeVisualisation();
      featureTypeVisualisation.featureType = row.featureType;
      featureTypeVisualisation.properties = row.properties;
      request.featureTypeVisualisations.push(featureTypeVisualisation);
    });

    const accessToken = await getAccessTokenSilently();

    const headers = new Headers();
    headers.set("Authorization", "Bearer " + accessToken);

    try {
      const resp: AddMapVisualisationResponse =
        await zoneAdminClient.addMapVisualisation(request, {
          headers: headers,
          timeoutMs: 10000,
        });

      if (resp.mapVisualisation) {
        enqueueSnackbar(
          "New map visualisation " + resp.mapVisualisation.id + " stored",
          {
            variant: "success",
            autoHideDuration: 5000,
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }
        );
        navigate(applicationRoutes.mapVisualisation.to);
      } else {
        enqueueSnackbar("Adding new map visualisation failed", {
          variant: "error",
          autoHideDuration: 5000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      }

      console.log(resp);
    } catch (err) {
      enqueueSnackbar(
        grpcErrorTranslator("Error adding map visualisation", err),
        {
          variant: "error",
          autoHideDuration: 5000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        }
      );
    }
  }

  async function editExisting() {
    const request = new EditMapVisualisationCommand();
    const vis = new MapVisualisation();
    vis.name = name;
    vis.id = id!;

    const defaultProperties: FeatureTypeVisualisationProperties =
      new FeatureTypeVisualisationProperties();
    defaultProperties.fillColor = defaultFillColor!;
    vis.defaultProperties = defaultProperties;

    rows.forEach((row) => {
      const featureTypeVisualisation = new FeatureTypeVisualisation();
      featureTypeVisualisation.featureType = row.featureType;
      featureTypeVisualisation.properties = row.properties;
      vis.featureTypeVisualisations.push(featureTypeVisualisation);
    });

    request.mapVisualisation = vis;
    const accessToken = await getAccessTokenSilently();

    const headers = new Headers();
    headers.set("Authorization", "Bearer " + accessToken);

    try {
      const resp: EditMapVisualisationResponse =
        await zoneAdminClient.editMapVisualisation(request, {
          headers: headers,
          timeoutMs: 10000,
        });

      if (resp.mapVisualisation) {
        enqueueSnackbar(
          "Map visualisation " + resp.mapVisualisation.id + " updated",
          {
            variant: "success",
            autoHideDuration: 5000,
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }
        );
        navigate(applicationRoutes.mapVisualisation.to);
      } else {
        enqueueSnackbar("Editing map visualisation failed", {
          variant: "error",
          autoHideDuration: 5000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      }

      console.log(resp);
    } catch (err) {
      enqueueSnackbar(
        grpcErrorTranslator("Error editing map visualisation", err),
        {
          variant: "error",
          autoHideDuration: 5000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        }
      );
    }
  }

  const columns: GridColDef[] = [
    {
      field: "featureType",
      headerName: "Feature type",
      minWidth: 400,
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<
          FeatureTypeVisualisation,
          any,
          any,
          GridTreeNodeWithRender
        >
      ) => {
        return (
          <Autocomplete
            key={"featuretype-" + params.id}
            sx={{ width: "100%" }}
            options={allFeatureTypes}
            groupBy={(option) => option.id.substring(0, option.id.indexOf(":"))}
            value={params.row.featureType}
            onChange={(_event: any, newValue: FeatureType | null) => {
              rows.filter(
                (row: FeatureTypeVisualisationRow) => row.id === params.id
              )[0].featureType = newValue!;
              console.log(newValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            autoHighlight
            getOptionLabel={(option) => option.id + " (" + option.name + ")"}
            renderInput={(params) => <TextField {...params} />}
          />
        );
      },
    },
    {
      field: "fillColor",
      headerName: "Fill color",
      flex: 1,
      minWidth: 200,
      type: "string",
      editable: false,
      renderCell: (
        params: GridRenderCellParams<
          FeatureTypeVisualisation,
          any,
          any,
          GridTreeNodeWithRender
        >
      ) => {
        return (
          <MuiColorInput
            key={"fill-color-" + params.id}
            value={params.row.properties!.fillColor}
            format="hex8"
            fallbackValue="#FF000088"
            isAlphaHidden={false}
            onChange={(newValue: string) => {
              rows.filter(
                (row: FeatureTypeVisualisationRow) => row.id === params.id
              )[0].properties!.fillColor = newValue;
            }} // TODO update correct place*/
          />
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <RequirePermission to={AUTHORIZATION_ADMIN_WRITE} fallback={<div><Typography variant="h6" component="h1">Unauthorized - you do not have the necessary permissions to see this function</Typography></div>}>

    <Container maxWidth={false} disableGutters>
      <Typography variant="h4" component="h1" gutterBottom>
        {props.mode === MapVisualisationFormMode.CREATE ? "Create " : null}
        {props.mode === MapVisualisationFormMode.EDIT ? "Edit existing " : null}
        {props.mode === MapVisualisationFormMode.COPY ? "Copy existing " : null}
        Map visualisation
      </Typography>
      <Typography variant="h6" component="h1" gutterBottom>
        A map visualisation is a configuration of how the various feature
        types/geo zones should be rendered on a map.
      </Typography>
      <TextField
        label="Map friendly name"
        onChange={(e) => setName(e.target.value)}
        required
        variant="outlined"
        color="secondary"
        type="text"
        sx={{ mb: 3 }}
        fullWidth
        value={name}
      />
      Default Fill Color for all feature types unless overridden per type
      <br />
      <MuiColorInput
        key="default-fill-color"
        value={defaultFillColor}
        format="hex8"
        fallbackValue="#FF000088"
        onChange={(newValue: string) => setDefaultFillColour(newValue)}
      />
      <br />
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        rowHeight={80}
      />
      {props.mode === MapVisualisationFormMode.EDIT ? (
        <Button variant="contained" type="submit" onClick={editExisting}>
          Update
        </Button>
      ) : null}
      {props.mode === MapVisualisationFormMode.COPY ||
      props.mode === MapVisualisationFormMode.CREATE ? (
        <Button variant="contained" type="submit" onClick={addNew}>
          Save new
        </Button>
      ) : null}
    </Container>
    </RequirePermission>
  );
};
