import { useParams } from "react-router-dom";
import { LoggedInPageLayout } from "../../components/page-layout";
import { Container } from "@mui/material";

import {
  MapVisualisationForm,
  MapVisualisationFormMode,
} from "src/components/map-visualisation-form";

export const MapVisualisationEditPage: React.FC = () => {
  const { id } = useParams();

  return (
    <LoggedInPageLayout>
      <Container maxWidth={false}>
        <MapVisualisationForm
          mode={MapVisualisationFormMode.EDIT}
          id={BigInt(id!)}
        />
      </Container>
    </LoggedInPageLayout>
  );
};
