import { LoggedInPageLayout } from "../../components/page-layout";
import { Container } from "@mui/material";

import {
  DatasetDefinitionForm,
  DatasetDefinitionFormMode,
} from "src/components/dataset-definition-form";

export const DatasetDefinitionNewPage: React.FC = () => {
  return (
    <LoggedInPageLayout>
      <Container maxWidth={false}>
        <DatasetDefinitionForm mode={DatasetDefinitionFormMode.CREATE} />
      </Container>
    </LoggedInPageLayout>
  );
};
