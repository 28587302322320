import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Container, Box, Typography } from "@mui/material";

export const CallbackPage: React.FC = () => {
  const { error } = useAuth0();

  if (error) {
    return (
      <Container>
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Error authenticating
          </Typography>
          {error.message}
        </Box>
      </Container>
    );
  }

  return <div></div>;
};
