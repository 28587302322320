import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Box,
  CssBaseline,
} from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import { useNavigate } from "react-router-dom";

import InboxIcon from "@mui/icons-material/MoveToInbox";

import logo from "../images/SmallLogo.png";
import { LogoutButton } from "./buttons/logout-button";
import { applicationRoutes } from "src/config";

import PermissionProvider from "src/authorization/auth-provider";
import { AUTHORIZATION_ADMIN_READ, Permission } from "../authorization/auth-types";
import { useAuth0 } from "@auth0/auth0-react";
import RequirePermission from "src/authorization/require-permission";
import { jwtDecode } from "jwt-decode";

interface Props {
  children: JSX.Element;
}

type DecodedToken = {
  aud: string[];
  azp: string;
  exp: number;
  iat: number;
  iss: string;
  permissions: string[];
  scope: string;
  sub: string;
};

export const LoggedInPageLayout: React.FC<Props> = ({ children }) => {
  let navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const { getAccessTokenSilently } = useAuth0();
  const fetchPermission =
    () =>
    async (permission: Permission): Promise<boolean> => {
      return new Promise<boolean>((resolve, reject) => {
        getAccessTokenSilently()
          .then((claims) => {
            const decoded: DecodedToken = jwtDecode(claims);
            const result = decoded.permissions.includes(permission);
         resolve(result);
          })
          .catch((err) => {
            console.debug("Error obtaining access token", err);
            reject();
          });
      });
    };

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    path: string
  ) => {
    setSelectedIndex(index);
    navigate(path);
  };

  return (
    <div>
      <PermissionProvider fetchPermission={fetchPermission()}>
        <CssBaseline />
        <Grid container>
          <Grid xs={11}></Grid>

          <Grid>
            <LogoutButton />
          </Grid>
          <Grid xs={12}>
            <Box sx={{ display: "flex" }}>
              <Box>
                <Drawer
                  sx={{
                    width: 250,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                      width: 250,
                      boxSizing: "border-box",
                    },
                  }}
                  variant="permanent"
                  anchor="left"
                >
                  <img src={logo} width="80" alt="logo" />
                  <RequirePermission to={AUTHORIZATION_ADMIN_READ} fallback={<div>Missing admin:read permission</div>}>
          
                  <List>
                    <ListItem key="datasetDefinitions" disablePadding>
                      <ListItemButton
                        selected={selectedIndex === 0}
                        onClick={(event) =>
                          handleListItemClick(
                            event,
                            0,
                            applicationRoutes.datasetDefinitions.to
                          )
                        }
                      >
                        <ListItemIcon>
                          <InboxIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={applicationRoutes.datasetDefinitions.name}
                        />
                      </ListItemButton>
                    </ListItem>
                    <ListItem key="datasetJobs" disablePadding>
                      <ListItemButton
                        selected={selectedIndex === 1}
                        onClick={(event) =>
                          handleListItemClick(
                            event,
                            1,
                            applicationRoutes.datasetJobs.to
                          )
                        }
                      >
                        <ListItemIcon>
                          <InboxIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={applicationRoutes.datasetJobs.name}
                        />
                      </ListItemButton>
                    </ListItem>

                      <ListItem key="datasetCoverage" disablePadding>
                        <ListItemButton
                          selected={selectedIndex === 1}
                          onClick={(event) =>
                            handleListItemClick(
                              event,
                              1,
                              applicationRoutes.datasetCoverage.to
                            )
                          }
                        >
                          <ListItemIcon>
                            <InboxIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={applicationRoutes.datasetCoverage.name}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="mapVisualisations" disablePadding>
                        <ListItemButton
                          selected={selectedIndex === 1}
                          onClick={(event) =>
                            handleListItemClick(
                              event,
                              1,
                              applicationRoutes.mapVisualisation.to
                            )
                          }
                        >
                          <ListItemIcon>
                            <InboxIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={applicationRoutes.mapVisualisation.name}
                          />
                        </ListItemButton>
                      </ListItem>
                  </List>
                  </RequirePermission>
                </Drawer>
              </Box>
              <div style={{ width: "100%", height: "100%" }}>{children}</div>
            </Box>
          </Grid>
        </Grid>
      </PermissionProvider>{" "}
    </div>
  );
};
