const envSettings = window as any;
export class Config {
  static api_core_url = envSettings.API_CORE_URL;
  static auth0_domain = envSettings.AUTH0_DOMAIN;
  static auth0_clientId = envSettings.AUTH0_CLIENT_ID;
  static auth0_redirectUrl = envSettings.AUTH0_CALLBACK_URL;
  static auth0_audience = envSettings.AUTH0_AUDIENCE;
  static mapbox_accesstoken = envSettings.MAPBOX_ACCESSTOKEN;
}

export const applicationRoutes = {
  datasetDefinitions: {
    to: "/dataset/definition",
    name: "Dataset Definitions",
  },
  datasetDefinitionsNew: {
    to: "/dataset/definition/new",
    name: "New Dataset Definition",
  },
  datasetDefinitionsEdit: {
    to: "/dataset/definition/edit",
    name: "Edit existing Dataset Definition",
  },
  datasetDefinitionsCopy: {
    to: "/dataset/definition/copy",
    name: "Copy existing Dataset Definition",
  },
  datasets: {
    to: "/dataset",
    name: "Datasets",
  },
  datasetJobs: {
    to: "/dataset/jobs",
    name: "Dataset Jobs",
  },
  datasetCoverage: {
    to: "/dataset/coverage",
    name: "Dataset Coverage",
  },
  mapVisualisation: {
    to: "/mapvisualisation",
    name: "Map Visualisations",
  },
  mapVisualisationNew: {
    to: "/mapvisualisation/new",
    name: "New Map Visualisation",
  },
  mapVisualisationEdit: {
    to: "/mapvisualisation/edit",
    name: "Edit existing Map Visualisation",
  },
  mapVisualisationCopy: {
    to: "/mapvisualisation/copy",
    name: "Copy existing Map Visualisation",
  },
};
