import { LoggedInPageLayout } from "../../components/page-layout";
import { Container } from "@mui/material";

import {
  MapVisualisationForm,
  MapVisualisationFormMode,
} from "src/components/map-visualisation-form";

export const MapVisualisationNewPage: React.FC = () => {
  return (
    <LoggedInPageLayout>
      <Container maxWidth={false}>
        <MapVisualisationForm mode={MapVisualisationFormMode.CREATE} />
      </Container>
    </LoggedInPageLayout>
  );
};
