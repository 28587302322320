import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { LoggedInPageLayout } from "../../components/page-layout";

import {
  DataGrid,
  GridColDef,
  GridRowSelectionModel,
  GridValueGetterParams,
  GridActionsCellItem,
  GridRowId,
  GridRowParams,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid";

import {
  Edit,
  NotStarted,
  PauseCircle,
  ContentCopy,
} from "@mui/icons-material";
import { Button, Typography, Container, Chip } from "@mui/material";

import { useSnackbar } from "notistack";
import { intervalToDuration, formatDuration } from "date-fns";

import {
  DatasetDefinition,
  RemoteDataSourceType,
  GetDatasetDefinitionsCommand,
  GetDatasetDefinitionsResponse,
  FetchDatasetCommand,
  FetchDatasetResponse,
  GetDatasetsCommand,
  GetDatasetsResponse,
  ActivateDatasetDefinitionCommand,
  Dataset,
} from "@airdodge-private/api-typescript-complete/lib/io/airdodge/internal/v1/GeoZonesAdmin_API_Commands_pb";

import { GeoZonesAdminService } from "@airdodge-private/api-typescript-complete/lib/io/airdodge/internal/v1/GeoZonesAdmin_API_connect";
import { FeatureType } from "@airdodge-private/api-typescript-complete/lib/io/airdodge/dtsp/geozones/v1/GeoZones_Common_pb";
import { zoneAdminClient } from "../../api-client";
import { useClient } from "../../api-client";
import { grpcErrorTranslator } from "src/helper/grpc-error-translator";

import { applicationRoutes } from "src/config";
import { useAuth0 } from "@auth0/auth0-react";
import RequirePermission from "src/authorization/require-permission";
import usePermission from "src/authorization/usePermission";
import { AUTHORIZATION_ADMIN_WRITE,AUTHORIZATION_ADMIN_READ } from "src/authorization/auth-types";


interface DatasetDefinitionRow {
  active: boolean;
  id: number;
  name: string;
  geographical_coverage: string;
  datasource_type: string | undefined;
  update_interval: string | undefined;
  dataset: Dataset | undefined;
  feature_types: FeatureType[];
}

var selectedDatasetDefinitionIds: bigint[];

export const DatasetDefinitionListPage: React.FC = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState<readonly any[]>([]);
  const client = useClient(GeoZonesAdminService);
  const { user } = useAuth0();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, allowedAdminWrite] = usePermission(AUTHORIZATION_ADMIN_WRITE);

  useEffect(() => {
    let isMounted = true;

    const loadDatasetDefinitions = async () => {
      const accessToken = await getAccessTokenSilently();

      const datasetDefinitionsRequest = new GetDatasetDefinitionsCommand({});
      const headers = new Headers();
      headers.set("Authorization", "Bearer " + accessToken);

      try {
        const datasetDefinitionRsp: GetDatasetDefinitionsResponse =
          await client.getDatasetDefinitions(datasetDefinitionsRequest, {
            headers: headers,
            timeoutMs: 10000,
          });

        console.log(datasetDefinitionRsp);

        const datasetRequest = new GetDatasetsCommand({});
        datasetRequest.latest = true;
        datasetRequest.datasetDefinitionId =
          datasetDefinitionRsp.datasetDefinitions.map(
            (datasetDefinition: DatasetDefinition) => datasetDefinition.id
          );

        const datasetRsp: GetDatasetsResponse = await client.getDatasets(
          datasetRequest,
          {
            headers: headers,
            timeoutMs: 10000,
          }
        );
        const parsedRows = datasetDefinitionRsp?.datasetDefinitions.map(
          (dd) =>
            ({
              active: dd.active,
              id: Number(dd.id),
              name: dd.name,
              geographical_coverage: dd.geographicalCoverage,
              datasource_type: dd.dataSource?.type,
              update_interval: dd.updateInterval?.seconds,
              dataset: datasetRsp.dataset.filter(
                (dataset) => dataset.datasetDefinitionId === dd.id
              )[0],
              feature_types: dd.featuresIncluded,
            }) as DatasetDefinitionRow
        );

        setRows(parsedRows);
      } catch (err) {
        enqueueSnackbar(
          grpcErrorTranslator("Error fetching dataset definitions", err),
          {
            variant: "error",
            autoHideDuration: 5000,
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }
        );
      }

      if (!isMounted) {
        return;
      }
    };

    loadDatasetDefinitions();

    return () => {
      isMounted = false;
    };
  }, [client, getAccessTokenSilently]);
  if (!user) {
    return null;
  }

  const onRowsSelectionHandler = (ids: GridRowSelectionModel) => {
    selectedDatasetDefinitionIds = ids
      .map((id) => rows.find((row) => row.id === id))
      .map((row) => row.id);
    console.log(selectedDatasetDefinitionIds);
  };

  async function fetchDataset() {
    console.log("Fetch datasets " + selectedDatasetDefinitionIds);
    const accessToken = await getAccessTokenSilently();

    const request = new FetchDatasetCommand({});
    request.datasetDefinitionId = selectedDatasetDefinitionIds;
    const headers = new Headers();
    headers.set("Authorization", "Bearer " + accessToken);

    try {
      const resp: FetchDatasetResponse = await zoneAdminClient.fetchDataset(
        request,
        {
          headers: headers,
          timeoutMs: 10000,
        }
      );

      resp.datasetJob.forEach((job) => {
        enqueueSnackbar(
          "Job " +
            job.id +
            " for dataset " +
            job.datasetDefinitionId +
            " queued",
          {
            variant: "success",
            autoHideDuration: 5000,
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }
        );

        // TODO clear selection
      });

      console.log(resp);
    } catch (err) {
      enqueueSnackbar(
        grpcErrorTranslator("Error queueing dataset fetch job", err),
        {
          variant: "error",
          autoHideDuration: 5000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        }
      );
    }
  }

  const activateDatasetDefinition = (id: GridRowId) => () => {
    console.log("Activate " + id);
    activateDataset(Number(id), true);
  };

  const deactivateDatasetDefinition = (id: GridRowId) => () => {
    activateDataset(Number(id), false);
  };

  const copyDatasetDefinition = (id: GridRowId) => () => {
    navigate(applicationRoutes.datasetDefinitionsCopy.to + "/" + id);
  };
  const editDatasetDefinition = (id: GridRowId) => () => {
    navigate(applicationRoutes.datasetDefinitionsEdit.to + "/" + id);
  };

  async function activateDataset(id: number, activate: boolean) {
    const accessToken = await getAccessTokenSilently();

    const request = new ActivateDatasetDefinitionCommand({});
    request.datasetDefinitionId = BigInt(id);
    request.activate = activate;
    const headers = new Headers();
    headers.set("Authorization", "Bearer " + accessToken);

    try {
      await zoneAdminClient.activateDatasetDefinition(request, {
        headers: headers,
        timeoutMs: 10000,
      });

      enqueueSnackbar("Dataset definition " + id + " activated/deactivated", {
        variant: "success",
        autoHideDuration: 5000,
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      // update row with new status
      const newRows = rows.map((row) => {
        if (row.id === id) {
          return { ...row, active: activate };
        }
        return row;
      });
      setRows(newRows);
    } catch (err) {
      enqueueSnackbar(
        grpcErrorTranslator("Error activating/deactivating definitions", err),
        {
          variant: "error",
          autoHideDuration: 5000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        }
      );
    }
  }

  const columns: GridColDef[] = [
    {
      field: "active",
      headerName: "Actions",
      type: "actions",
      width: 140,

      cellClassName: "actions",

      getActions: (params: GridRowParams) =>
       // TODO: Should also be conditional on ... !loading && allowedAdminWrite ? 
         
          [
              <GridActionsCellItem
                icon={<Edit />}
                label={applicationRoutes.datasetDefinitionsEdit.name}
                onClick={editDatasetDefinition(params.id)}
              />,
              <GridActionsCellItem
                icon={<ContentCopy />}
                label={applicationRoutes.datasetDefinitionsCopy.name}
                onClick={copyDatasetDefinition(params.id)}
              />,
              <GridActionsCellItem
                icon={<NotStarted />}
                label="Start fetching on a regular interval"
                disabled={params.row.active}
                onClick={activateDatasetDefinition(params.id)}
              />,
              <GridActionsCellItem
                icon={<PauseCircle />}
                label="Pause fetching on a regular interval"
                disabled={!params.row.active}
                onClick={deactivateDatasetDefinition(params.id)}
              />,
            ]
          //: []
          
    },

    {
      field: "id",
      headerName: "ID",
      width: 60,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 250,
      editable: false,
    },
    {
      field: "geographical_coverage",
      headerName: "Area",
      width: 100,
      type: "string",
      editable: false,
    },
    {
      field: "datasource_type",
      headerName: "Datasource type",
      flex: 1,
      minWidth: 120,
      type: "string",
      editable: false,
      valueGetter: (
        params: GridValueGetterParams<any, any, GridTreeNodeWithRender>
      ) => {
        switch (params.row.datasource_type) {
          case RemoteDataSourceType.ARCGIS_FEATURE_SERVICE:
            return "ArcGIS Cloud";
          case RemoteDataSourceType.OVERPASS_API:
            return "OpenStreetMap";
          case RemoteDataSourceType.LFV_DAIM:
            return "LFV DAIM";
          case RemoteDataSourceType.OPENAIP:
            return "OpenAIP";
          case RemoteDataSourceType.STATIC:
            return "Static";
          default:
            return "Unknown " + params.row.datasource_type;
        }
      },
    },
    {
      field: "update_interval",
      headerName: "Update interval",

      width: 120,
      type: "string",
      editable: false,

      valueGetter: (
        params: GridValueGetterParams<any, any, GridTreeNodeWithRender>
      ) => {
        if (!params.row.update_interval) {
          return null;
        }
        const duration = intervalToDuration({
          start: 0,
          end: Number(params.row.update_interval) * 1000,
        });
        // { minutes: 30, seconds: 7 }

        const zeroPad = (num: number) => String(num).padStart(1, "0");

        const formatted = formatDuration(duration, {
          format: ["days"],
          // format: ["hours", "minutes", "seconds"],
          zero: true,
          delimiter: "days",
          locale: {
            formatDistance: (_token, count) => zeroPad(count),
          },
        });

        return formatted + " days";
      },
    },

    {
      field: "last_dataset",
      headerName: "Last fetched",

      width: 120,
      type: "dateTime",
      editable: false,
      valueGetter: (
        params: GridValueGetterParams<any, any, GridTreeNodeWithRender>
      ) => {
        if (!params.row.dataset) {
          return null;
        }

        return new Date(Number(params.row.dataset.updated.seconds) * 1000);
      },
    },
    {
      field: "feature_types",
      headerName: "Feature types",
      flex: 10,
      //width: 500,
      type: "string",
      editable: false,

      renderCell: (params) => (
        <ul className="flex-flow">
          {params.value.map((featureType: FeatureType, index: number) => (
            <Chip key={index} label={featureType.id} variant={"outlined"} />
          ))}
        </ul>
      ),
      /*
      renderCell: (params) => {
        if (!params.value) {
          return null;
        }
        return <Chip label={params.value} variant={"outlined"} />;
      },*/
    },
  ];

  return (
    <LoggedInPageLayout>
      <Container maxWidth={false}>
        <Typography variant="h4" component="h1" gutterBottom>
          Dataset definitions
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          A dataset definition defines a data source to fetch data from as well
          as metadata about the source.
        </Typography>

        <RequirePermission to={AUTHORIZATION_ADMIN_WRITE}>
          <Button
            variant="contained"
            onClick={() => navigate(applicationRoutes.datasetDefinitionsNew.to)}
          >
            Create new
          </Button>
        </RequirePermission>

        <DataGrid
          getRowId={(row) => row.id}
          rows={rows}
          onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          columns={columns}
          getRowHeight={() => "auto"}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15,
              },
            },
          }}
          pageSizeOptions={[10, 15, 50, 100]}
          checkboxSelection
          disableRowSelectionOnClick
        />

        <RequirePermission to="admin:write">
          <Button variant="contained" onClick={fetchDataset}>
            Fetch selected
          </Button>
        </RequirePermission>
      </Container>
    </LoggedInPageLayout>
  );
};
