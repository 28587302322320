import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { LoggedInPageLayout } from "../../components/page-layout";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridActionsCellItem,
  GridRowParams,
  GridRowId,
} from "@mui/x-data-grid";
import { Button, Typography, Container } from "@mui/material";

import { Edit, ContentCopy } from "@mui/icons-material";

import {
  FeatureTypeVisualisationProperties,
  ListMapVisualisationsCommand,
  ListMapVisualisationsResponse,
} from "@airdodge-private/api-typescript-complete/lib/io/airdodge/internal/v1/GeoZonesAdmin_API_Commands_pb";

import { GeoZonesAdminService } from "@airdodge-private/api-typescript-complete/lib/io/airdodge/internal/v1/GeoZonesAdmin_API_connect";
import { useClient } from "../../api-client";
import { grpcErrorTranslator } from "src/helper/grpc-error-translator";
import { useSnackbar } from "notistack";

import { useAuth0 } from "@auth0/auth0-react";
import { applicationRoutes } from "src/config";
import RequirePermission from "src/authorization/require-permission";
import { AUTHORIZATION_ADMIN_WRITE } from "src/authorization/auth-types";

interface MapVisualisationRow {
  id: number;
  name: String;
  defaultProperties: FeatureTypeVisualisationProperties;
}

export const MapVisualisationListPage: React.FC = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();

  const [rows, setRows] = useState<readonly any[]>([]);
  const { getAccessTokenSilently } = useAuth0();
  const client = useClient(GeoZonesAdminService);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let isMounted = true;

    const loadMapVisualisations = async () => {
      const accessToken = await getAccessTokenSilently();

      const req = new ListMapVisualisationsCommand({});

      const headers = new Headers();
      headers.set("Authorization", "Bearer " + accessToken);

      try {
        const rsp: ListMapVisualisationsResponse =
          await client.listMapVisualisations(req, {
            headers: headers,
            timeoutMs: 10000,
          });

        console.log(rsp);

        const parsedRows = rsp.mapVisualisations.map(
          (mapVisualisation) =>
            ({
              id: Number(mapVisualisation.id),
              name: mapVisualisation.name,
              defaultProperties: mapVisualisation.defaultProperties,
            }) as MapVisualisationRow
        );

        setRows(parsedRows);
      } catch (err) {
        enqueueSnackbar(
          grpcErrorTranslator("Error loading map visualisations", err),
          {
            variant: "error",
            autoHideDuration: 5000,
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }
        );
      }

      if (!isMounted) {
        return;
      }
    };

    loadMapVisualisations();

    return () => {
      isMounted = false;
    };
  }, [client, getAccessTokenSilently]);
  if (!user) {
    return null;
  }

  const columns: GridColDef[] = [
    {
      field: "active",
      headerName: "Actions",
      type: "actions",
      width: 140,

      cellClassName: "actions",

      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          onClick={editDatasetDefinition(params.id)}
        />,
        <GridActionsCellItem
          icon={<ContentCopy />}
          label="Copy selected"
          onClick={copyDatasetDefinition(params.id)}
        />,
      ],
    },

    {
      field: "id",
      headerName: "Id",
      minWidth: 100,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 100,
      flex: 5,
    },
    {
      field: "defaultProperties",
      headerName: "Default properties",
      flex: 10,
      //width: 500,
      type: "string",
      editable: false,
      renderCell: (
        params: GridRenderCellParams<
          MapVisualisationRow,
          any,
          any,
          GridTreeNodeWithRender
        >
      ) => {
        return (
          <ul>
            <li>Fill colour: {params.row.defaultProperties.fillColor}</li>
          </ul>
        );
      },
    },
  ];

  const copyDatasetDefinition = (id: GridRowId) => () => {
    navigate(applicationRoutes.mapVisualisationCopy.to + "/" + id);
  };
  const editDatasetDefinition = (id: GridRowId) => () => {
    navigate(applicationRoutes.mapVisualisationEdit.to + "/" + id);
  };

  return (
    <LoggedInPageLayout>
      <Container maxWidth={false}>
        <Typography variant="h4" component="h1" gutterBottom>
          Map visualisations
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          Style information on how to render various geozones are stored in map
          visualisations. Each map has a default set of properties that are
          overridden for each feature type / geozone.
        </Typography>

        <RequirePermission to={AUTHORIZATION_ADMIN_WRITE}>
          <Button
            variant="contained"
            onClick={() => navigate(applicationRoutes.mapVisualisationNew.to)}
          >
            Create new
          </Button>
        </RequirePermission>
        <DataGrid
          rows={rows}
          getRowId={(row) => row.id}
          columns={columns}
          getRowHeight={() => "auto"}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          disableRowSelectionOnClick
        />
      </Container>
    </LoggedInPageLayout>
  );
};
