import React from "react";
import { LoggedInPageLayout } from "../components/page-layout";
import { Container, Typography } from "@mui/material";

export const NotFoundPage: React.FC = () => {
  return (
    <LoggedInPageLayout>
      <Container maxWidth={false}>
        <Typography variant="h4" component="h1" gutterBottom>
          Not found! Please report URL to developer alongside with where you
          clicked to get here
        </Typography>
      </Container>
    </LoggedInPageLayout>
  );
};
