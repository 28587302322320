import React, { useEffect, useState } from "react";

import { LoggedInPageLayout } from "../components/page-layout";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Chip, Typography, Container } from "@mui/material";

import {
  GetFeatureTypeCoverageCommand,
  GetFeatureTypeCoverageResponse,
} from "@airdodge-private/api-typescript-complete/lib/io/airdodge/internal/v1/GeoZonesAdmin_API_Commands_pb";
import { FeatureType } from "@airdodge-private/api-typescript-complete/lib/io/airdodge/dtsp/geozones/v1/GeoZones_Common_pb";

import { GeoZonesAdminService } from "@airdodge-private/api-typescript-complete/lib/io/airdodge/internal/v1/GeoZonesAdmin_API_connect";
import { useClient } from "../api-client";
import { ConnectError, Code } from "@connectrpc/connect";
import { grpcErrorTranslator } from "src/helper/grpc-error-translator";
import { useSnackbar } from "notistack";

import { useAuth0 } from "@auth0/auth0-react";

interface DatasetCoverageRow {
  geopgrahical_coverage: string;
  included_feature_types: FeatureType[];
  missing_feature_types: FeatureType[];
}

export const DatasetCoveragePage: React.FC = () => {
  const { user } = useAuth0();

  const [rows, setRows] = useState<readonly any[]>([]);
  const { getAccessTokenSilently } = useAuth0();
  const client = useClient(GeoZonesAdminService);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let isMounted = true;

    const loadFeatureTypeCoverage = async () => {
      const accessToken = await getAccessTokenSilently();

      const req = new GetFeatureTypeCoverageCommand({});

      const headers = new Headers();
      headers.set("Authorization", "Bearer " + accessToken);

      try {
        const rsp: GetFeatureTypeCoverageResponse =
          await client.getFeatureTypeCoverage(req, {
            headers: headers,
            timeoutMs: 10000,
          });

        console.log(rsp);

        const parsedRows = rsp.coverage.map(
          (job) =>
            ({
              geopgrahical_coverage: job.countryId,
              included_feature_types: job.includedFeatureTypes,
              missing_feature_types: job.missingFeatureTypes,
            } as DatasetCoverageRow)
        );

        setRows(parsedRows);
      } catch (err) {
        enqueueSnackbar(
          grpcErrorTranslator("Error loading dataset definition", err),
          {
            variant: "error",
            autoHideDuration: 5000,
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }
        );
      }

      if (!isMounted) {
        return;
      }
    };

    loadFeatureTypeCoverage();

    return () => {
      isMounted = false;
    };
  }, [client, getAccessTokenSilently]);
  if (!user) {
    return null;
  }

  const columns: GridColDef[] = [
    {
      field: "geopgrahical_coverage",
      headerName: "Geo coverage",
      minWidth: 100,
    },
    {
      field: "included_feature_types",
      headerName: "Included Feature types",
      flex: 10,
      //width: 500,
      type: "string",
      editable: false,

      renderCell: (params) => (
        <ul className="flex-flow">
          {params.value.map((featureType: FeatureType, index: number) => (
            <Chip key={index} label={featureType.id} variant={"outlined"} />
          ))}
        </ul>
      ),
    },
    {
      field: "missing_feature_types",
      headerName: "Missing Feature types",
      flex: 10,
      //width: 500,
      type: "string",
      editable: false,

      renderCell: (params) => (
        <ul className="flex-flow">
          {params.value.map((featureType: FeatureType, index: number) => (
            <Chip key={index} label={featureType.id} variant={"outlined"} />
          ))}
        </ul>
      ),
    },
  ];

  return (
    <LoggedInPageLayout>
      <Container maxWidth={false}>
        <Typography variant="h4" component="h1" gutterBottom>
          Coverage overview
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          Each definition provides a set of feature types. This page shows per
          country which feature types are included and which are missing.
        </Typography>

        <DataGrid
          rows={rows}
          getRowId={(row) => row.geopgrahical_coverage}
          columns={columns}
          getRowHeight={() => "auto"}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          disableRowSelectionOnClick
        />
      </Container>
    </LoggedInPageLayout>
  );
};
