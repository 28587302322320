import { useParams } from "react-router-dom";
import { LoggedInPageLayout } from "../../components/page-layout";
import { Container } from "@mui/material";

import {
  DatasetDefinitionForm,
  DatasetDefinitionFormMode,
} from "src/components/dataset-definition-form";

export const DatasetDefinitionCopyPage: React.FC = () => {
  const { id } = useParams();

  return (
    <LoggedInPageLayout>
      <Container maxWidth={false}>
        <DatasetDefinitionForm
          mode={DatasetDefinitionFormMode.COPY}
          id={BigInt(id!)}
        />
      </Container>
    </LoggedInPageLayout>
  );
};
