import { LoggedInPageLayout } from "../components/page-layout";
import { Container, Typography } from "@mui/material";

export const HomePage: React.FC = () => {
  return (
    <LoggedInPageLayout>
      <Container>
      <Typography variant="h4" component="h1" gutterBottom>
          This is the administration console for AirDodge.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          This page will at some point in time serve as a dashboard. Until then, choose something from the lefthand menu.
        </Typography>
      </Container>
    </LoggedInPageLayout>
  );
};
