import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { AuthenticationGuard } from "./components/authentication-guard";
import { Route, Routes } from "react-router-dom";
import { CallbackPage } from "./pages/callback-page";
import { HomePage } from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";
import { DatasetDefinitionListPage } from "./pages/dataset-definitions/dataset-definition-list-page";
import { DatasetDefinitionNewPage } from "./pages/dataset-definitions/dataset-definition-new-page";
import { DatasetDefinitionEditPage } from "./pages/dataset-definitions/dataset-definition-edit-page";
import { DatasetDefinitionCopyPage } from "./pages/dataset-definitions/dataset-definition-copy-page";
import { DatasetJobsPage } from "./pages/dataset-jobs-page";
import { DatasetCoveragePage } from "./pages/dataset-coverage-page";
import { MapVisualisationListPage } from "./pages/map-visualisation/map-visualisation-list-page";
import { CircularProgress } from "@mui/material";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { SnackbarProvider } from "notistack";
import { applicationRoutes } from "./config";
import { MapVisualisationEditPage } from "./pages/map-visualisation/map-visualisation-edit-page";
import { MapVisualisationCopyPage } from "./pages/map-visualisation/map-visualisation-copy-page";
import { MapVisualisationNewPage } from "./pages/map-visualisation/map-visualisation-new-page";

export const App: React.FC = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }
  return (
    <SnackbarProvider maxSnack={3}>
      <Routes>
        <Route
          path="/"
          element={<AuthenticationGuard component={HomePage} />}
        />
        <Route
          path={applicationRoutes.datasetDefinitions.to}
          element={<AuthenticationGuard component={DatasetDefinitionListPage} />}
        />
        <Route
          path={applicationRoutes.datasetDefinitionsNew.to}
          element={<AuthenticationGuard component={DatasetDefinitionNewPage} />}
        />
        <Route path={applicationRoutes.datasetDefinitionsEdit.to}>
          <Route
            path=":id"
            element={
              <AuthenticationGuard component={DatasetDefinitionEditPage} />
            }
          />
        </Route>
        <Route path={applicationRoutes.datasetDefinitionsCopy.to}>
          <Route
            path=":id"
            element={
              <AuthenticationGuard component={DatasetDefinitionCopyPage} />
            }
          />
        </Route>
        <Route
          path={applicationRoutes.datasetJobs.to}
          element={<AuthenticationGuard component={DatasetJobsPage} />}
        />
        <Route
          path={applicationRoutes.datasetCoverage.to}
          element={<AuthenticationGuard component={DatasetCoveragePage} />}
        />
        <Route
          path={applicationRoutes.mapVisualisation.to}
          element={<AuthenticationGuard component={MapVisualisationListPage} />}
        />
        <Route
          path={applicationRoutes.mapVisualisationNew.to}
          element={<AuthenticationGuard component={MapVisualisationNewPage} />}
        />
        <Route path={applicationRoutes.mapVisualisationEdit.to}>
          <Route
            path=":id"
            element={
              <AuthenticationGuard component={MapVisualisationEditPage} />
            }
          />
        </Route>
        <Route path={applicationRoutes.mapVisualisationCopy.to}>
          <Route
            path=":id"
            element={
              <AuthenticationGuard component={MapVisualisationCopyPage} />
            }
          />
        </Route>

        <Route path="/callback" element={<CallbackPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </SnackbarProvider>
  );
};
