import { ConnectError, Code } from "@connectrpc/connect";

export function grpcErrorTranslator(
  functionDependentMessage: string,
  error: any
): string {
  var errorMessage: string = functionDependentMessage + " (" + error + ")";

  if (error instanceof ConnectError) {
    // TODO add more detailed messages / support for validation errors etc

    const codeName = Code[error.code];
    errorMessage =
      functionDependentMessage + " (" + error.code + "/" + codeName + ")";
  }

  return errorMessage;
}
